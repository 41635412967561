import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-unit-item',
  templateUrl: './unit-item.component.html',
  styleUrls: ['./unit-item.component.css']
})
export class UnitItemComponent implements OnInit {

  @Input() unit: any;
  @Input() unitOptions: any;
  unitForm: FormGroup;

  constructor(
    fb: FormBuilder
  ) {
    this.unitForm = fb.group({
      unitOptions: ['', Validators.compose([Validators.required])],
      number: ['1', Validators.compose([Validators.required])]
    });
  }

  ngOnInit() {

    if (!this.unit.number) {
      this.unit.number = 1;
    }

  }

}

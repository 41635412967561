import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  @Input() category: any;

  constructor( ) { }

  ngOnInit() {
    this.category.squads = [];
    this.setupMinUnits();
  }

  setupMinUnits() {
    if (this.category.min > 0) {
      this.category.squads.push({});
    }
  }

  addSquad(){
    if (this.category.squads.length >= this.category.max) {
      return;
    }
    this.category.squads.push({});
  }

}

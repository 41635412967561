import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnitsComponent } from './units/units.component';
import { UnitComponent } from './unit/unit.component';
import { RouterModule } from '@angular/router';
import { UnitWeaponComponent } from './unit-weapon/unit-weapon.component';
import { OrderModule } from 'ngx-order-pipe';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    OrderModule
  ],
  declarations: [UnitsComponent, UnitComponent, UnitWeaponComponent]
})
export class UnitsModule { }

import { Component, OnInit } from '@angular/core';
import { UnitsService } from 'src/app/core/services/units/units.service';

import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.css']
})
export class UnitsComponent implements OnInit {

  units: Array<any>;
  force: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private unitsService: UnitsService
  ) { }

  ngOnInit() {
    this.route.params.subscribe((val) => {

      if (!val) {
        this.router.navigate(['/']);

        return;
      }

      this.force = val.force;
      this.getUnitsForFaction(this.force);

    });

  }

  getUnitsForFaction(force: string) {
    this.unitsService.getUnitsForForce(force).subscribe((response) => {
      this.units = response;
    });
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ForceService {

  force: string;

  constructor() {
    this.force = 'PHR';
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BuilderComponent } from './builder/builder.component';
import { BattlegroupComponent } from './battlegroup/battlegroup.component';
import { CategoryComponent } from './category/category.component';
import { SquadComponent } from './squad/squad.component';
import { UnitItemComponent } from './unit-item/unit-item.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [BuilderComponent, BattlegroupComponent, CategoryComponent, SquadComponent, UnitItemComponent]
})
export class BuilderModule { }

import { Component, OnInit, Input } from '@angular/core';
import { BattlegroupService } from 'src/app/core/services/battlegroup/battlegroup.service';

@Component({
  selector: 'app-battlegroup',
  templateUrl: './battlegroup.component.html',
  styleUrls: ['./battlegroup.component.css']
})
export class BattlegroupComponent implements OnInit {

  @Input() battleGroup: any;

  constructor(
    private battleGroupService: BattlegroupService
  ) { }

  ngOnInit() {
    this.setupBattleGroupCategories();
  }

  setupBattleGroupCategories() {
    const categories = JSON.stringify(this.battleGroupService.availableCategories[this.battleGroup.type]);
    this.battleGroup.categories = JSON.parse(categories);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class BattlegroupService {

    availableCategories: any;

    constructor(
        private http: HttpClient
    ) {
        this.setupAvailableCategories();
    }

    setupAvailableCategories() {
        this.getCategories().subscribe((response) => {
            this.availableCategories = response;
        });
    }

    getCategories() {
        return this.http.get(`./assets/json/categories.json`);
    }


}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { UnitsComponent } from './modules/units/units/units.component';
import { UnitsModule } from './modules/units/units.module';
import { BuilderComponent } from './modules/builder/builder/builder.component';
import { BuilderModule } from './modules/builder/builder.module';

const appRoutes: Routes = [
  { path: 'units/:force', component: UnitsComponent },
  { path: 'builder/:force', component: BuilderComponent },
  { path: '**', component: AppComponent }
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(
      appRoutes
    ),
    UnitsModule,
    BuilderModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

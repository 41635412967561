import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-unit-weapon',
  templateUrl: './unit-weapon.component.html',
  styleUrls: ['./unit-weapon.component.css']
})
export class UnitWeaponComponent implements OnInit {

  @Input() weapon: any;

  constructor() { }

  ngOnInit() {
  }

}

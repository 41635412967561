import { Component, OnInit, Input } from '@angular/core';
import { ForceService } from 'src/app/core/services/force/force.service';
import { UnitsService } from 'src/app/core/services/units/units.service';

@Component({
  selector: 'app-squad',
  templateUrl: './squad.component.html',
  styleUrls: ['./squad.component.css']
})
export class SquadComponent implements OnInit {

  @Input() squad: any;
  @Input() squadCategory: string;
  unitOptions: Array<any>;

  constructor(
    private forceService: ForceService,
    private unitsService: UnitsService
  ) { }

  ngOnInit() {
    this.squad.units = [];
    this.getUnitOptionsForSquad(this.squadCategory);
  }

  addSquad(){
    this.squad.units.push({});
  }

  getUnitOptionsForSquad(squadCategory: string) {
    this.unitsService.getUnitsForForce(this.forceService.force).subscribe((response) => {

      const units = response.filter(function (unit: any) {
        return unit.Category === squadCategory;
      });

      const unitsObject = JSON.stringify(units);
      this.unitOptions = JSON.parse(unitsObject);

    });

  }

}

import { Component, OnInit } from '@angular/core';
import { BattlegroupService } from 'src/app/core/services/battlegroup/battlegroup.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ForceService } from 'src/app/core/services/force/force.service';

@Component({
  selector: 'app-builder',
  templateUrl: './builder.component.html',
  styleUrls: ['./builder.component.css']
})
export class BuilderComponent implements OnInit {

  battleGroups: Array<any> = [];
  newBattleGroupVisible = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private battleGroupService: BattlegroupService,
    private forceService: ForceService
  ) { }

  ngOnInit() {
    this.route.params.subscribe((val) => {

      if (!val) {
        this.router.navigate(['/']);

        return;
      }

      this.forceService.force = val.force;

    });
  }

  showNewBattleGroup() {
    this.newBattleGroupVisible = true;
  }

  resetForce() {
    this.battleGroups = [];
    this.newBattleGroupVisible = false;
  }

  addBattleGroup(battleGroupType: string) {
    const newBattleGroup = {
      'id': this.battleGroups.length,
      'type': battleGroupType
    };

    this.battleGroups.push(newBattleGroup);
    this.newBattleGroupVisible = false;
  }

}
